import { StrictMode, useEffect } from 'react';
import { ChakraProvider, useToast } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';

import customTheme from './theme/theme';
import { msalConfig } from './config/msal';
import routesConfig from './config/routesConfig';
import { ApiProvider } from './api/ApiContext';
import { appInsights, reactPlugin } from './config/applicationInsights';
import customHistory from './config/history';

const queryClient = new QueryClient();
const msalInstance = new PublicClientApplication(msalConfig);

function AppRouting() {
  return (
    <BrowserRouter history={customHistory}>
      <Routes>
        {routesConfig.map((route) => (
          <Route key={route.path} path={route.path} element={route.element} />
        ))}
      </Routes>
    </BrowserRouter>
  );
}

function App() {
  const toast = useToast();

  useEffect(() => {
    async function initializeMsal() {
      try {
        await msalInstance.initialize();
      } catch (error) {
        toast({
          title: 'Authentication Error',
          description: 'Failed to initialize authentication services. Please try again later.',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
        appInsights?.trackException({
          error: new Error(`Failed to initialize authentication services. ${error.response.status}: ${error.config.url}`),
          severityLevel: 3, // Warning
          properties: {
            url: error.config.url,
            method: error.config.method,
            status: error.response.status,
            response: error.response.data,
          },
        });
      }
    }

    initializeMsal();
  }, [toast]);

  return (
    <StrictMode>
      <MsalProvider instance={msalInstance}>
        <ChakraProvider theme={customTheme}>
          {/* reactPlugin is passed to AppInsightsContext.Provider in order to provide access
              to the Application Insights instance throughout the React component tree,
              enabling the use of error boundaries and custom telemetry tracking
              within components. */}
          <AppInsightsContext.Provider value={reactPlugin}>
            <QueryClientProvider client={queryClient}>
              <ApiProvider>
                <AppRouting />
              </ApiProvider>
              {/* ReactQueryDevtools is not imported when process.env.NODE_ENV === 'production' */}
              <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
          </AppInsightsContext.Provider>
        </ChakraProvider>
      </MsalProvider>
    </StrictMode>
  );
}

export default App;
