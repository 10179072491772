const Checkbox = {
  baseStyle: {
    icon: {
      stroke: 'white',
      strokeWidth: '0.65',
      width: '4',
      height: '4',
    },
    control: {
      height: '6', // works only when resetting defaultProps
      width: '6', // works only when resetting defaultProps
      border: '1px',
      borderColor: 'gray.400',
      borderRadius: 'none',
      _hover: {
        '@media (hover: hover) and (pointer: fine)': {
          bg: 'gray.200',
          borderColor: 'black',
          outline: '8px solid',
          outlineColor: 'gray.200',
        },
      },
      _checked: {
        borderColor: 'gray.900',
        bg: 'gray.900',
        _hover: {
          borderColor: 'gray.900',
          bg: 'gray.900',
        },
        _disabled: { bg: 'white', color: 'gray.700', borderColor: 'gray.300' },
      },
      _indeterminate: {
        borderColor: 'gray.900',
        bg: 'gray.900',
        _hover: {
          '@media (hover: hover) and (pointer: fine)': {
            borderColor: 'gray.900',
            bg: 'gray.900',
          },
        },
      },
      _focusVisible: {
        borderRadius: 'none',
        boxShadow: 'none',
      },
    },
    label: {
      width: '100%',
      ml: '3',
      fontSize: 'sm',
      fontWeight: 'medium',
      color: 'black',
    },
  },
  defaultProps: {
    // Reset props
    size: null,
    variant: null,
  },
};

export default Checkbox;
