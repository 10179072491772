const Menu = {
  parts: ['list', 'item'],
  baseStyle: {
    list: {
      borderRadius: 0,
    },
    item: {
      padding: '12px 16px 8px 16px',
      background: 'none',
      height: '40px',
      lineHeight: '20px',
      width: '100%',
      whiteSpace: 'nowrap',
      fontWeight: '400',
      '&[aria-checked=true]': {
        color: 'geberit.500',
      },
      svg: {
        strokeWidth: 1,
      },
      _hover: {
        background: 'gray.150',
      },
      _focus: {
        background: 'none',
      },
    },
  },
};

export default Menu;
