import {
  Box,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import {
  useNavigate,
  useParams,
} from 'react-router-dom';
import {
  useEffect,
  useState,
} from 'react';

import useUserStore from '../../services/UserStore';
import { routes } from '../../utils/constants';
import { getTestID } from '../../utils/utils';
import TabUserManagement from './TabUserManagement/TabUserManagement';
import TabUserSettings from './TabUserSettings';
import TabPersonalData from './TabPersonalData';
import TabUsageLog from './TabUsageLog';

const tabs = [
  'personalData',
  'userSettings',
  'userManagement',
  'usageLog',
];

function Settings() {
  const { t } = useTranslation();
  const user = useUserStore((s) => s.user);
  const { tab } = useParams();
  const navigate = useNavigate();

  const [tabIndex, setTabIndex] = useState(tabs.indexOf(tab) === -1 ? 0 : tabs.indexOf(tab));

  const handleTabsChange = (index) => {
    setTabIndex(index);
    navigate(`${routes.SETTINGS}/${tabs[index]}`);
  };

  useEffect(() => {
    setTabIndex(tabs.indexOf(tab) === -1 ? 0 : tabs.indexOf(tab));
  }, [tab]);

  return (
    <Box data-testid={getTestID('User')}>
      {user && (
        <>
          <Heading as="h1" size="h1">
            {t('hello')}
            {' '}
            {user.fullName}
          </Heading>
          <Tabs isLazy index={tabIndex} onChange={handleTabsChange}>
            <TabList>
              <Tab>{t('personalData')}</Tab>
              <Tab>{t('userSettings')}</Tab>
              {(user.role === 'ADMIN') && <Tab>{t('userManagement')}</Tab>}
              {(user.role === 'ADMIN') && <Tab>{t('usageLog.tabName')}</Tab>}
            </TabList>
            <TabPanels>
              <TabPanel>
                <TabPersonalData user={user} />
              </TabPanel>
              <TabPanel>
                <TabUserSettings />
              </TabPanel>
              {(user.role === 'ADMIN') && (
                <TabPanel>
                  <TabUserManagement />
                </TabPanel>
              )}
              {(user.role === 'ADMIN') && (
                <TabPanel>
                  <TabUsageLog />
                </TabPanel>
              )}
            </TabPanels>
          </Tabs>
        </>
      )}
    </Box>
  );
}

export default Settings;
