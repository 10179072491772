import {
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  Button,
  Spacer,
  Flex,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { getTestID } from '../../../../utils/utils';
import ArrowDownIcon from '../../../../icons/ArrowDownIcon';
import Error from '../../../ui/Error';
import GeberitSpinner from '../../../ui/GeberitSpinner';
import UserRow from './UserRow';

const getSecondColumnTitle = (type, hasRolesAssigned, t) => {
  if (type === 'active' && hasRolesAssigned) {
    return t('role');
  }
  if (type === 'pending') {
    return t('pendingTimestamp');
  }
  return '';
};

function CommonTable({
  type, users, hasRolesAssigned, loading, fetchNextPage, hasNextPage, isFetchingNextPage,
  error = null,
}) {
  const { t } = useTranslation();
  const hasUsers = users?.pages[0]?.users?.length > 0;
  const usersShown = users?.pages[0] !== '' && users?.pages?.reduce((count, curr) => count + curr.users.length, 0);
  const usersTotal = users?.pages[0]?.rowTotal;
  const usersCount = `${usersShown} ${t('catalogues.pagination.of')} ${usersTotal}`;
  const tableHeader = type === 'active' && !hasRolesAssigned ? t('defaultUsers') : t(`${type}Users`);

  return (
    <TableContainer marginBottom="8">
      {loading && <GeberitSpinner />}
      {error && <Error error={error} />}
      {hasUsers && (
        <>
          <Table variant="basic">
            <TableCaption placement="top" data-testid={getTestID('TableCaption')}>
              {tableHeader}
            </TableCaption>
            <Thead>
              <Tr>
                <Th style={{ width: '300px' }}>{t('name')}</Th>
                <Th>{getSecondColumnTitle(type, hasRolesAssigned, t)}</Th>
                <Th>{type === 'active' && hasRolesAssigned ? t('assignedLocales') : null}</Th>
                <Th />
                <Th style={{ width: '115px' }}>{t('user.lastLogin')}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {!loading && !error && users?.pages?.map((page) => page.users?.map((user) => (
                <UserRow key={user.uuid} user={user} type={type} />
              )))}
            </Tbody>
          </Table>
          <Flex>
            <Spacer />
            {hasNextPage && (
              <Button
                onClick={fetchNextPage}
                disabled={!hasNextPage || isFetchingNextPage}
                leftIcon={<ArrowDownIcon />}
              >
                {isFetchingNextPage ? t('loadingMore') : `${t('loadMore')} ${t(tableHeader.toLowerCase())}`}
              </Button>
            )}
            <Spacer />
            {hasUsers && (
              <Text data-testid={getTestID('CatalogueCounter')} alignSelf="center">
                {usersCount}
              </Text>
            )}
          </Flex>
        </>
      )}
    </TableContainer>
  );
}

CommonTable.propTypes = {
  type: PropTypes.string.isRequired,
  users: PropTypes.shape({
    pages: PropTypes.arrayOf(PropTypes.shape({
      users: PropTypes.arrayOf(PropTypes.shape({
        uuid: PropTypes.string.isRequired,
      })).isRequired,
      rowTotal: PropTypes.string,
    })).isRequired,
  }).isRequired,
  hasRolesAssigned: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  fetchNextPage: PropTypes.func.isRequired,
  hasNextPage: PropTypes.bool.isRequired,
  isFetchingNextPage: PropTypes.bool.isRequired,
  error: PropTypes.shape({ message: PropTypes.string }),
};

export default CommonTable;
