import Welcome from '../components/Welcome/Welcome';
import ProtectedRoute from '../components/common/ProtectedRoute';
import AuthorizedRoute from '../components/common/AuthorizedRoute';
import CatalogueList from '../components/CatalogueList/CatalogueList';
import SystemStatus from '../components/SystemStatus/SystemStatus';
import Settings from '../components/Settings/Settings';
import Catalogue from '../components/Catalogue/Catalogue';
import Revision from '../components/Revision/Revision';
import RevisionCreate from '../components/RevisionCreate/RevisionCreate';
import CatalogueCreate from '../components/CatalogueCreate/CatalogueCreate';
import CatalogueDuplicate from '../components/CatalogueCreate/CatalogueDuplicate';
import ArticleAvailability from '../components/ArticleAvailability/ArticleAvailability';
import DexAssetsPortal from '../components/DexAssetsPortal/DexAssetsPortal';
import GenerateRevision from '../components/DexAssetsPortal/GenerateRevisionFromURL/GenerateRevision';
import Overview from '../components/Overview/Overview';
import PageNotFound from '../components/404';
import { roles, routes } from '../utils/constants';

const routesConfig = [
  {
    path: routes.WELCOME,
    element: <Welcome />,
  },
  {
    path: routes.BLANK,
    element: null,
  },
  {
    path: routes.PAGE_NOT_FOUND,
    element: (
      <ProtectedRoute>
        <AuthorizedRoute permissions={[roles.MDM, roles.LDES, roles.DEFAULT, roles.ADMIN]}>
          <PageNotFound />
        </AuthorizedRoute>
      </ProtectedRoute>
    ),
  },
  {
    path: routes.ROOT,
    element: (
      <ProtectedRoute>
        <AuthorizedRoute permissions={[roles.MDM, roles.LDES, roles.ADMIN]}>
          <CatalogueList />
        </AuthorizedRoute>
      </ProtectedRoute>
    ),
  },
  {
    path: `${routes.SYSTEM_STATUS}/:tab`,
    element: (
      <ProtectedRoute>
        <AuthorizedRoute permissions={[roles.MDM, roles.LDES, roles.ADMIN]}>
          <SystemStatus />
        </AuthorizedRoute>
      </ProtectedRoute>
    ),
  },
  {
    path: routes.SYSTEM_STATUS,
    element: (
      <ProtectedRoute>
        <AuthorizedRoute permissions={[roles.MDM, roles.LDES, roles.ADMIN]}>
          <SystemStatus />
        </AuthorizedRoute>
      </ProtectedRoute>
    ),
  },
  {
    path: `${routes.SETTINGS}/:tab`,
    element: (
      <ProtectedRoute>
        <AuthorizedRoute permissions={[roles.MDM, roles.LDES, roles.ADMIN]}>
          <Settings />
        </AuthorizedRoute>
      </ProtectedRoute>
    ),
  },
  {
    path: routes.SETTINGS,
    element: (
      <ProtectedRoute>
        <AuthorizedRoute permissions={[roles.MDM, roles.LDES, roles.ADMIN]}>
          <Settings />
        </AuthorizedRoute>
      </ProtectedRoute>
    ),
  },
  {
    path: `${routes.CATALOGUE}/:uuid`,
    element: (
      <ProtectedRoute>
        <AuthorizedRoute permissions={[roles.MDM, roles.LDES, roles.ADMIN]}>
          <Catalogue />
        </AuthorizedRoute>
      </ProtectedRoute>
    ),
  },
  {
    path: `${routes.REVISION}/:uuid`,
    element: (
      <ProtectedRoute>
        <AuthorizedRoute permissions={[roles.MDM, roles.LDES, roles.ADMIN]}>
          <Revision />
        </AuthorizedRoute>
      </ProtectedRoute>
    ),
  },
  {
    path: routes.CREATE_REVISION,
    element: (
      <ProtectedRoute>
        <AuthorizedRoute permissions={[roles.MDM, roles.LDES, roles.ADMIN]}>
          <RevisionCreate />
        </AuthorizedRoute>
      </ProtectedRoute>
    ),
  },
  {
    path: routes.TRANSFORMATIONS,
    element: (
      <ProtectedRoute>
        <AuthorizedRoute permissions={[roles.MDM, roles.LDES, roles.ADMIN]}>
          <CatalogueCreate />
        </AuthorizedRoute>
      </ProtectedRoute>
    ),
  },
  {
    path: routes.CLONE_CATALOGUE,
    element: (
      <ProtectedRoute>
        <AuthorizedRoute permissions={[roles.MDM, roles.LDES, roles.ADMIN]}>
          <CatalogueDuplicate />
        </AuthorizedRoute>
      </ProtectedRoute>
    ),
  },
  {
    path: routes.ARTICLE_AVAILABILITY,
    element: (
      <ProtectedRoute>
        <AuthorizedRoute permissions={[roles.MDM, roles.LDES, roles.ADMIN, roles.DEFAULT]}>
          <ArticleAvailability />
        </AuthorizedRoute>
      </ProtectedRoute>
    ),
  },
  {
    path: routes.DEX_ASSETS_PORTAL,
    element: (
      <ProtectedRoute>
        <AuthorizedRoute permissions={[roles.MDM, roles.LDES, roles.DEFAULT, roles.ADMIN]}>
          <DexAssetsPortal />
        </AuthorizedRoute>
      </ProtectedRoute>
    ),
  },
  {
    path: `${routes.DEX_ASSETS_PORTAL}/:locale/:articleNumber`,
    element: (
      <ProtectedRoute>
        <AuthorizedRoute permissions={[roles.MDM, roles.LDES, roles.DEFAULT, roles.ADMIN]}>
          <GenerateRevision />
        </AuthorizedRoute>
      </ProtectedRoute>
    ),
  },
  {
    path: routes.OVERVIEW,
    element: (
      <ProtectedRoute>
        <AuthorizedRoute permissions={[roles.DEFAULT]}>
          <Overview />
        </AuthorizedRoute>
      </ProtectedRoute>
    ),
  },
  {
    path: routes.ANY,
    element: (
      <ProtectedRoute>
        <AuthorizedRoute permissions={[roles.MDM, roles.LDES, roles.DEFAULT, roles.ADMIN]}>
          <PageNotFound />
        </AuthorizedRoute>
      </ProtectedRoute>
    ),
  },
];

export default routesConfig;
