import {
  Button,
  IconButton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import {
  useState,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { ViewportList } from 'react-viewport-list';

import ModalDeleteFileExport from './ModalDeleteFileExport';
import Error from '../ui/Error';
import GeberitSpinner from '../ui/GeberitSpinner';
import {
  formatDateTime,
  getLocale,
} from '../../utils/utils';
import useUserStore from '../../services/UserStore';
import DownloadIcon from '../../icons/DownloadIcon';
import { useDownloadDataExport, useFileExports } from '../../api/hooks';

function FileExports() {
  const { t } = useTranslation();
  const [fileExport, setFileExport] = useState();
  const [fileExportDelete, setFileExportDelete] = useState(false);
  const user = useUserStore((s) => s.user);
  const ref = useRef(null);

  const { data: locales, isLoading, error: queryError } = useFileExports();
  const { downloadDataExport, progress, error } = useDownloadDataExport();

  return (
    <TableContainer
      className="scroll-container"
      ref={ref}
    >
      {isLoading && <GeberitSpinner />}
      {error && <Error error={error} />}
      {queryError && <Error error={queryError} />}
      {!isLoading && locales && (
        <Table>
          <Thead>
            <Tr>
              <Th>{t('name')}</Th>
              <Th>{t('fileExports.dataTimestamp')}</Th>
              <Th>{t('market')}</Th>
              <Th />
            </Tr>
          </Thead>
          <Tbody>
            <ViewportList
              viewportRef={ref}
              items={locales}
            >
              {(item, i, arr) => (
                <Tr
                  key={item.uuid}
                  background="gray.50"
                  _hover={{ backgroundColor: 'gray.200' }}
                >
                  {(user.role === 'MDM' || user.role === 'ADMIN') ? (
                    <Td>
                      <IconButton
                        className="highlight-action"
                        aria-label="download"
                        onClick={() => {
                          downloadDataExport(item.filename, item.locale);
                          setFileExport(item);
                        }}
                        icon={progress && fileExport.uuid === item.uuid
                          ? <GeberitSpinner />
                          : <DownloadIcon />}
                      />
                      {item.filename}
                    </Td>
                  ) : <Td>{item.filename}</Td>}
                  <Td>
                    {formatDateTime(item.syncDate)}
                  </Td>
                  <Td>{getLocale(item.locale)}</Td>
                  <Td>
                    <Button
                      variant="link"
                      color="error"
                      isDisabled={item.locale !== arr[i - 1]?.locale}
                      onClick={() => {
                        setFileExportDelete(true);
                        setFileExport(item);
                      }}
                    >
                      {t('delete')}
                    </Button>
                  </Td>
                </Tr>
              )}
            </ViewportList>
          </Tbody>
        </Table>
      )}
      {fileExportDelete && (
        <ModalDeleteFileExport
          fileExport={fileExport}
          open={fileExportDelete}
          setOpen={setFileExportDelete}
        />
      )}
    </TableContainer>
  );
}

export default FileExports;
