import { inputTypes } from '../../utils/constants';
import parseArticleKeys from '../CatalogueCreate/parseArticleKeys';

export const exchangeStandard = 'Article-Assets-Anonymous';

// Hides the standard preset fields from the user behind only one dropdown field.
export const customPresets = [
  {
    name: 'downloadAssetsOrLinks',
    style: 'select',
    type: 'list',
    values: [
      'inputSelectValues.excelWithLinks',
      'inputSelectValues.excelWithLinksAndAssets',
    ],
    default: 'Excel with links',
    mandatory: true,
  },
];

/*
 The predefined assortment field is not present in the API.
 This mimics its presence in the UI but does not send data to the back-end.
 If the article list field is left empty,
 then the revison will return data for all articles in a given locale.
*/
export const customAssortment = {
  name: 'assortmentAnonymous',
  style: 'select',
  type: 'list',
  values: [
    'inputSelectValues.allArticlesSoldInCountry',
  ],
  default: 'inputSelectValues.allArticlesSoldInCountry',
  mandatory: true,
};

// Prepares the input fields and their values for the request
export const sanitizeData = (formValues, transformationData) => {
  // Extract unique article IDs from the article list
  const validArticles = parseArticleKeys(formValues.articleList).uniqueIDs.join('\n');

  // Parse multi-select values into a comma-separated string
  const parseMultiSelectValues = (values) => (Array.isArray(values) ? values.filter(Boolean).join(',') : values);

  // Check if the custom preset is set to 'excelWithLinksAndAssets'
  const isExcelWithLinksAndAssetsPreset = () => formValues[customPresets[0].name] === 'inputSelectValues.excelWithLinksAndAssets';

  // Map transformation presets to the expected format
  const mapPresets = () => transformationData.presets.map((preset) => ({
    name: preset.name,
    value: !!isExcelWithLinksAndAssetsPreset(),
  }));

  // Map transformation parameters to the expected format
  const mapParameters = () => transformationData.parameters.map((parameter) => {
    if (parameter.name === 'articleListAnonymous' && validArticles) {
      return {
        name: parameter.name,
        value: parseArticleKeys(formValues[parameter.name]).uniqueIDs.join('\n'),
      };
    }

    if (parameter.style === inputTypes.MULTISELECT) {
      return {
        name: parameter.name,
        value: parseMultiSelectValues(formValues[parameter.name]),
      };
    }

    return {
      name: parameter.name,
      value: formValues[parameter.name],
    };
  });

  // Create the final catalogue object with sanitized data
  const createCatalogue = () => ({
    name: exchangeStandard,
    description: '',
    exchangeStandard,
    locale: formValues.localeAnonymous,
    presets: mapPresets(),
    parameters: mapParameters(),
  });

  // Return the sanitized catalogue data
  return createCatalogue();
};

export const generatedInputValues = (locale, articleNumber, transformationData) => {
  const catalogue = {};
  const validArticles = parseArticleKeys(articleNumber).uniqueIDs.join('\n');
  catalogue.name = exchangeStandard;
  catalogue.description = '';
  catalogue.exchangeStandard = exchangeStandard;
  catalogue.locale = locale;
  catalogue.presets = transformationData.presets.map((p) => ({
    name: p.name,
    value: true,
  }));
  catalogue.parameters = transformationData.parameters
    .map((p) => {
      if (p.name === 'localeAnonymous' && validArticles) {
        return {
          name: p.name,
          value: locale,
        };
      }
      if (p.name === 'articleListAnonymous' && validArticles) {
        return {
          name: p.name,
          value: validArticles,
        };
      }
      if (p.style === inputTypes.MULTISELECT) {
        return {
          name: p.name,
          value: p.values.join(','),
        };
      }
      /* Use articleNumberAndAssetType as the default value
      for the case of the deep-link generated packages */
      if (p.name === 'assetNamingMethod') {
        return {
          name: p.name,
          value: 'articleNumberAndAssetType',
        };
      }
      if (p.type === inputTypes.LIST && p.style === inputTypes.SELECT) {
        return {
          name: p.name,
          value: 'true',
        };
      }
      return {
        name: p.name,
        value: '',
      };
    });

  return catalogue;
};

export const getPresetInitialValues = () => customPresets
  .map((preset) => {
    if (preset.style === inputTypes.MULTISELECT) {
      return { [preset.name]: preset.default?.split(',') };
    }
    return { [preset.name]: preset.default || '' };
  })
  .reduce((acc, curr) => ({ ...acc, ...curr }), {});

export const getParameterInitialValues = (
  transformationData,
  selectedCountry,
) => transformationData.parameters
  .map((parameter) => {
    if (parameter.name === 'localeAnonymous' && parameter.values.includes(selectedCountry)) {
      return { [parameter.name]: selectedCountry || parameter.default };
    }
    if (parameter.style === inputTypes.MULTISELECT) {
      return { [parameter.name]: parameter.default?.split(',') };
    }
    return { [parameter.name]: parameter.default || '' };
  })
  .reduce((acc, curr) => ({ ...acc, ...curr }), {});
