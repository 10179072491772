import { useState } from 'react';
import {
  Button,
  Flex,
} from '@chakra-ui/react';
import {
  Form,
  Formik,
} from 'formik';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function FormStepper({
  children,
  initialValues,
  validationSchema = [],
}) {
  const { t } = useTranslation();

  const [step, setStep] = useState(0);
  const currentChild = children[step];
  const currentSchema = validationSchema[step];

  function isLastStep() {
    return step === children.length - 1;
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={currentSchema}
      autoComplete="off"
      onSubmit={(_, actions) => {
        if (isLastStep()) {
          actions.resetForm({ values: initialValues });
          setStep(0);
        } else {
          setStep((s) => s + 1);
        }
      }}
    >
      {({ isValid, dirty, isSubmitting }) => (
        <Form>
          {isLastStep() && (
            <Flex
              justifyContent="flex-end"
              gap="8"
              marginTop="8"
            >
              <Button
                type="submit"
                variant="primary"
                isDisabled={(!isValid || !dirty || isSubmitting) && !isLastStep()}
              >
                {t('articleAvailability.startNewCheck')}
              </Button>
            </Flex>
          )}
          {currentChild}
          <Flex
            justifyContent="flex-end"
            gap="8"
            marginTop="8"
          >
            <Button
              type="submit"
              variant="primary"
              isDisabled={(!isValid || !dirty || isSubmitting) && !isLastStep()}
            >
              {isLastStep()
                ? t('articleAvailability.startNewCheck')
                : t('continue')}
            </Button>
          </Flex>
        </Form>
      )}
    </Formik>
  );
}

FormStepper.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  initialValues: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  validationSchema: PropTypes.arrayOf(PropTypes.shape({
    fields: PropTypes.shape(),
  })),
};

export default FormStepper;
