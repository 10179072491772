import {
  Box,
  Button,
  Flex,
  Heading,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from 'react-use';
import PropTypes from 'prop-types';

import ModalAddCollaborator from './ModalAddCollaborator';
import ModalUnsubscribe from './ModalUnsubscribe';
import GeberitSpinner from '../ui/GeberitSpinner';
import { getTestID } from '../../utils/utils';
import { useCatalogueCollaborators } from '../../api/hooks';
import CollaboratorsTable from './CollaboratorsTable';

function CollaboratorsHeader({
  collaborators = [], userUuid, catalogue, setShowAdd, setShowUnsubscribe,
}) {
  const { t } = useTranslation();
  const view = useBreakpointValue({
    base: 'mobile',
    sm: 'desktop',
    md: 'desktop',
  });

  return (
    <Flex direction="column" marginBottom="8">
      <Flex justifyContent="space-between" alignItems="center">
        <Heading margin={0}>
          {t('collaborators.name')}
          &nbsp;
          {collaborators?.length >= 0 ? (
            <Text as="span">
              {`(${collaborators?.length})`}
            </Text>
          ) : (
            <GeberitSpinner />
          )}
        </Heading>
        {view === 'desktop' && (
          userUuid === catalogue.requestedBy.uuid ? (
            <Button variant="link" onClick={() => setShowAdd(true)}>
              {`+ ${t('collaborator.add')}`}
            </Button>
          ) : (
            <Button variant="secondary" onClick={() => setShowUnsubscribe(true)}>
              {t('collaborator.unsubscribe')}
            </Button>
          )
        )}
      </Flex>
      {view === 'mobile' && (
        <Flex alignContent="flex-start">
          {(userUuid === catalogue.requestedBy.uuid) ? (
            <Button variant="link" onClick={() => setShowAdd(true)} mt="4" pl="0">
              {`+ ${t('collaborator.add')}`}
            </Button>
          ) : (
            <Button variant="secondary" onClick={() => setShowUnsubscribe(true)} mt="4" pl="0">
              {t('collaborator.unsubscribe')}
            </Button>
          )}
        </Flex>
      )}
    </Flex>
  );
}

CollaboratorsHeader.propTypes = {
  collaborators: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })),
  userUuid: PropTypes.string.isRequired,
  catalogue: PropTypes.shape({
    catalogueUuid: PropTypes.string.isRequired,
    requestedBy: PropTypes.shape({
      uuid: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  setShowAdd: PropTypes.func.isRequired,
  setShowUnsubscribe: PropTypes.func.isRequired,
};
function Collaborators(props) {
  const { catalogue } = props;
  const [userUuid] = useLocalStorage('uuid');
  const [showAdd, setShowAdd] = useState();
  const [showUnsubscribe, setShowUnsubscribe] = useState();

  const { data: collaborators } = useCatalogueCollaborators(catalogue?.catalogueUuid);

  return (
    <Box data-testid={getTestID('Collaborators')}>
      <CollaboratorsHeader
        collaborators={collaborators || []}
        userUuid={userUuid}
        catalogue={catalogue}
        setShowAdd={setShowAdd}
        setShowUnsubscribe={setShowUnsubscribe}
      />
      <CollaboratorsTable
        collaborators={collaborators || []}
        userUuid={userUuid}
        catalogue={catalogue}
      />
      {showAdd && (
        <ModalAddCollaborator
          open={showAdd}
          setOpen={setShowAdd}
          catalogue={catalogue}
        />
      )}
      {showUnsubscribe && (
        <ModalUnsubscribe
          open={showUnsubscribe}
          setOpen={setShowUnsubscribe}
          collaborationUuid={collaborators?.filter((c) => c.user?.uuid === userUuid)[0].uuid}
        />
      )}
    </Box>
  );
}

Collaborators.propTypes = {
  catalogue: PropTypes.shape({
    catalogueUuid: PropTypes.string.isRequired,
    requestedBy: PropTypes.shape({
      uuid: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default Collaborators;
