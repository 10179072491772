import {
  Box,
  Text,
  FormLabel,
  Tooltip,
  FormControl,
} from '@chakra-ui/react';
import {
  Trans,
  useTranslation,
} from 'react-i18next';
import PropTypes from 'prop-types';
import { InfoOutlineIcon } from '@chakra-ui/icons';

import { getTestID } from '../../../utils/utils';
import GenericInput from './InputFields/GenericInput';

function Input({
  type, data, transformation,
  disabled = false, hint = '', autoFocus = false, label = '', searchbox = false,
}) {
  const { t, i18n } = useTranslation();
  const isRequired = data.required;
  const hasInfo = i18n.exists(`parameters.${data.name}.info`)
    || i18n.exists(`presets.${transformation}.${data.name}.info`);
  const info = type === 'parameter'
    ? <Trans i18nKey={`${type}s.${data.name}.info`} components={{ nl: <br /> }} />
    : <Trans i18nKey={`presets.${transformation}.${data.name}.info`} components={{ nl: <br /> }} />;

  return (
    <Box data-testid={getTestID('Input')}>
      {data && (
        <FormControl isRequired={isRequired}>
          <FormLabel>
            {`${t(label)} `}
            {hasInfo && (
              <Tooltip
                hasArrow
                placement="top"
                label={info}
              >
                <InfoOutlineIcon />
              </Tooltip>
            )}
          </FormLabel>
          <GenericInput
            autoFocus={autoFocus}
            data={data}
            disabled={disabled}
            type={type}
            searchbox={searchbox}
          />
          <Text size="sm" marginTop="1" color="gray.500">
            {hint}
          </Text>
        </FormControl>
      )}
    </Box>
  );
}

Input.propTypes = {
  type: PropTypes.string.isRequired,
  transformation: PropTypes.string.isRequired,
  data: PropTypes.shape({
    name: PropTypes.string,
    mandatory: PropTypes.bool,
    required: PropTypes.bool,
  }).isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  hint: PropTypes.string,
  autoFocus: PropTypes.bool,
  searchbox: PropTypes.bool,
};

export default Input;
