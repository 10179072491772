import FormStepper from './FormStepper';
import ArticleInput from './FormSteps/ArticleInput';
import validationSchema from './validationSchema';
import AvailabilityList from './FormSteps/AvailabilityList';

const initialValues = {
  article: '',
};

function ArticleAvailability() {
  return (
    <FormStepper
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(formValues) => formValues}
    >
      <ArticleInput />
      <AvailabilityList />
    </FormStepper>
  );
}

export default ArticleAvailability;
