import {
  Fragment,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Table,
  Tbody,
  Tr,
  Td,
  Collapse,
  Button,
  Box,
  Tooltip,
  Link,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import RevisionTableMobile from '../RevisionTable/RevisionTableMobile';

function CatalogueTableMobile({
  data, selectedIndexes, toggleRow,
}) {
  const { t } = useTranslation();

  const titleSize = useBreakpointValue({
    base: '100px',
    xs: '290px',
  });

  return (
    <Table variant="mobile">
      <Tbody>
        {data.pages.map((page) => (
          page.catalogues.map((catalogue) => (
            <Fragment key={catalogue.catalogueUuid}>
              <Tr className={selectedIndexes.includes(catalogue.catalogueUuid) ? 'selected' : 'normal'}>
                <Td>
                  <Box display="flex" flexDirection="column" gap="2">
                    <Box>
                      <Tooltip label={catalogue.name}>
                        <span>
                          <Link
                            variant="truncated"
                            to={`catalogue/${catalogue.catalogueUuid}`}
                            as={NavLink}
                            maxW={titleSize}
                            minW="100px"
                          >
                            {catalogue.name}
                          </Link>
                        </span>
                      </Tooltip>
                    </Box>
                    <Text>{t(`transformations.${catalogue.exchangeStandard}.pretty_name`)}</Text>
                    <Text color="#707070">{t(`locales.${catalogue?.locale}`)}</Text>
                    {catalogue.revisions?.length > 0 && (
                      <Button
                        size="sm"
                        variant="link"
                        onClick={() => toggleRow(catalogue.catalogueUuid)}
                        maxWidth="fit-content"
                        padding={0}
                      >
                        {selectedIndexes.includes(catalogue.catalogueUuid) ? 'Show less' : 'Show more'}
                      </Button>
                    )}
                  </Box>
                </Td>
              </Tr>

              {selectedIndexes.includes(catalogue.catalogueUuid) && (
                <Collapse in={selectedIndexes.includes(catalogue.catalogueUuid)} animateOpacity>
                  {catalogue.revisions?.length > 0 && (
                    <RevisionTableMobile
                      catalogue={catalogue}
                      revisions={catalogue.revisions}
                    />
                  )}
                </Collapse>
              )}
            </Fragment>
          ))
        ))}
      </Tbody>
    </Table>
  );
}

CatalogueTableMobile.propTypes = {
  data: PropTypes.shape({
    pages: PropTypes.arrayOf(PropTypes.shape({
      catalogues: PropTypes.arrayOf(PropTypes.shape({
        catalogueUuid: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
        region: PropTypes.string,
        exchangeStandard: PropTypes.string,
        language: PropTypes.string,
        // eslint-disable-next-line react/forbid-prop-types
        revisions: PropTypes.array,
      })).isRequired,
    })).isRequired,
  }).isRequired,
  selectedIndexes: PropTypes.arrayOf(PropTypes.string).isRequired,
  toggleRow: PropTypes.func.isRequired,
};

export default CatalogueTableMobile;
