import { useState } from 'react';
import {
  Box,
  Button,
  Menu as ChakraMenu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import {
  useNavigate,
  useParams,
} from 'react-router-dom';
import PropTypes from 'prop-types';

import { useContextMenu } from '../../api/hooks';
import EllipsisIcon from '../../icons/EllipsisIcon';
import { routes } from '../../utils/constants';
import { getTestID } from '../../utils/utils';
import ModalArchiveCatalogue from './ModalArchiveCatalogue';
import ModalDeleteCatalogue from './ModalDeleteCatalogue';
import ModalUpdateCatalogue from './ModalUpdateCatalogue';

function EllipsisMenu(props) {
  const { catalogue, transformation } = props;
  const { t } = useTranslation();
  const { uuid } = useParams();
  const navigate = useNavigate();

  const [showDelete, setShowDelete] = useState(false);
  const [showRename, setShowRename] = useState(false);
  const [showArchive, setShowArchive] = useState(false);

  const { data: contextMenu } = useContextMenu(uuid);

  const duplicationDisabled = !contextMenu?.find((item) => item.cloneCatalogueConfiguration);
  const deletionDisabled = !contextMenu?.find((item) => item.deleteCatalogue);
  const updateDisabled = !contextMenu?.find((item) => item.renameCatalogue);
  const archiveDisabled = !contextMenu?.find((item) => item.archiveCatalogue);

  return (
    <Box
      data-testid={getTestID('CatalogueContextMenu')}
      marginLeft="2"
    >
      {contextMenu?.length > 0 && (
        <ChakraMenu>
          <MenuButton
            data-testid={getTestID('dotDotDotMenuButton')}
            as={Button}
            variant="filledRound"
            padding="3"
          >
            <EllipsisIcon />
          </MenuButton>
          <MenuList>
            <MenuItem
              onClick={() => setShowRename(true)}
              isDisabled={updateDisabled}
            >
              {t('rename')}
            </MenuItem>
            <MenuItem
              onClick={() => navigate(
                routes.CLONE_CATALOGUE,
                { state: { catalogue, transformation } },
              )}
              isDisabled={duplicationDisabled}
            >
              {t('contextMenu.cloneCatalogueConfiguration')}
            </MenuItem>
            <MenuItem
              data-testid={getTestID('deleteCatalogueButton')}
              color="error"
              _hover={{ background: 'errorTransparency10' }}
              onClick={() => setShowDelete(true)}
              isDisabled={deletionDisabled}
            >
              {t('delete')}
            </MenuItem>
            <MenuItem
              data-testid={getTestID('archiveCatalogueButton')}
              onClick={() => setShowArchive(true)}
              isDisabled={archiveDisabled}
            >
              {t('contextMenu.archive')}
            </MenuItem>
          </MenuList>
        </ChakraMenu>
      )}
      <ModalDeleteCatalogue
        open={showDelete}
        setOpen={setShowDelete}
        catalogueUuid={catalogue?.catalogueUuid || ''}
      />
      {!archiveDisabled && (
        <ModalArchiveCatalogue
          open={showArchive}
          setOpen={setShowArchive}
          catalogueUuid={catalogue?.catalogueUuid || ''}
        />
      )}
      {showRename && (
        <ModalUpdateCatalogue
          open
          setOpen={setShowRename}
          catalogue={catalogue}
        />
      )}
    </Box>
  );
}

EllipsisMenu.propTypes = {
  catalogue: PropTypes.shape({
    catalogueUuid: PropTypes.string,
    exchangeStandard: PropTypes.string,
  }).isRequired,
  transformation: PropTypes.objectOf(
    PropTypes.shape({
      name: PropTypes.string,
    }),
  ).isRequired,
};

export default EllipsisMenu;
