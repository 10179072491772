import {
  Button,
  Flex,
  Heading,
  Image,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

function PageNotFound() {
  const errorImgSrc = '/geberit_error_generic.svg';
  const { t } = useTranslation();
  const navigate = useNavigate();

  const navigateHome = () => navigate('/');

  return (
    <Flex
      flexDirection={{ base: 'column-reverse', md: 'row' }}
      gap={{ base: 8, md: 18 }}
      alignItems="center"
      justifyContent="space-between"
    >
      <Flex
        flexDirection="column"
        mb={{ base: 4, sm: 6, md: 8 }}
        maxW={{ base: '100%', sm: '75%' }}
        w={{ base: '100%', sm: '75%' }}
      >
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Heading as="h1" size="4xl" mb={0} textTransform="uppercase" lineHeight="2.25">
            404
          </Heading>
          <Text fontSize={{ base: 'xl', sm: '2xl', md: '3xl' }} mt={10}>
            {t('pageNotFound')}
          </Text>
          <Button
            px={6}
            mt={10}
            border="1px solid"
            colorScheme="blue"
            variant="outline"
            onClick={navigateHome}
          >
            {t('backToOverview')}
          </Button>
        </Flex>
      </Flex>
      <Image
        src={errorImgSrc}
        boxSize={{ base: '72', sm: '87.5', md: '125' }}
        alt="Error message image"
        objectFit="cover"
      />
    </Flex>
  );
}

export default PageNotFound;
