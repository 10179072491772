// eslint-disable-next-line no-underscore-dangle
const _disabled = {
  background: 'gray.200',
  borderColor: 'gray.200',
  color: 'gray.400',
  opacity: 1,
};

const primary = {
  color: 'white',
  background: 'geberit.500',
  borderColor: 'geberit.500',
  _hover: {
    background: 'geberit.700',
    _disabled,
  },
  _disabled,
  '*': {
    color: 'white',
  },
};

const secondary = {
  background: 'white',
  border: '1px solid',
  borderColor: 'gray.600',
  _hover: {
    background: 'gray.100',
    _disabled,
  },
  _disabled,
};

const flat = {
  borderColor: 'transparent',
  _hover: {
    background: 'gray.100',
    _disabled: {
      color: 'gray.400',
      opacity: 1,
    },
  },
  _active: {
    // color: 'white',
    // borderColor: 'gray.900',
    // background: 'rgba(0,0,0,0.1)',
    // svg: {
    //   '*': {
    //     color: 'white',
    //   },
    // },
  },
  _disabled: {
    color: 'gray.400',
    opacity: 1,
  },
};

const filled = {
  background: 'gray.200',
  border: '1px solid',
  borderColor: 'transparent',
  _hover: {
    background: 'gray.400',
    _disabled,
  },
  _active: {
    color: 'white',
    borderColor: 'gray.900',
    background: 'gray.900',
    svg: {
      '*': {
        color: 'white',
      },
    },
  },
  _disabled,
};

const link = {
  padding: '12px 10px 8px 10px',
  borderColor: 'transparent',
  fontWeight: 'normal',
  color: 'geberit.500',
  '*': {
    color: 'geberit.500',
  },
  svg: {
    marginRight: '10px',
  },
};

const inlineLink = {
  display: 'contents',
  borderColor: 'transparent',
  padding: 0,
  margin: 0,
  fontWeight: '400',
  color: 'geberit.500',
};

const approve = {
  background: 'success',
  borderColor: 'success',
  color: 'white',
  _hover: {
    background: 'success',
    borderColor: 'success',
    _disabled: {
      background: 'successTransparency10',
      borderColor: 'successTransparency10',
    },
  },
  _disabled: {
    opacity: 1,
    background: 'successTransparency10',
    borderColor: 'successTransparency10',
  },
};

const decline = {
  borderColor: 'error',
  background: 'error',
  color: 'white',
  _hover: {
    background: 'error',
    borderColor: 'error',
    _disabled: {
      background: 'errorTransparency10',
      borderColor: 'errorTransparency10',
    },
  },
  _disabled: {
    opacity: 1,
    background: 'errorTransparency10',
    borderColor: 'errorTransparency10',
  },
};

const dropdown = {
  width: '100%',
  borderRadius: 0,
  fontWeight: 'inherit',
  whiteSpace: 'normal',
  borderColor: 'gray.200',
  _hover: {
    borderColor: 'black',
  },
  _focus: {
    boxShadow: 'none',
    borderColor: 'geberit.500',
  },
  _disabled: {
    background: 'gray.350',
    borderColor: 'gray.350',
    _hover: {
      borderColor: 'gray.350',
    },
  },
  _invalid: {
    boxShadow: 'none',
    borderColor: 'error',
    _focus: {
      borderColor: 'error',
    },
  },
};

const multiselect = {
  width: '100%',
  borderRadius: 0,
  fontWeight: '400',
  whiteSpace: 'normal',
  borderColor: 'gray.200',
  _hover: {
    borderColor: 'black',
  },
  _focus: {
    boxShadow: 'none',
    borderColor: 'geberit.500',
  },
  _disabled: {
    background: 'gray.350',
    borderColor: 'gray.350',
    _hover: {
      borderColor: 'gray.350',
    },
  },
  _invalid: {
    boxShadow: 'none',
    borderColor: 'error',
    _focus: {
      borderColor: 'error',
    },
  },
  span: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  /* Rotate arrow icon when the dropdown is open */
  '.rotateUp': {
    transition: 'transform 0.3s ease-in 0s',
    transform: 'rotate(-180deg)',
  },

  /* Rotate arrow icon when the dropdown is closed */
  '.rotateDown': {
    transition: 'transform 0.3s ease-in 0s',
    transform: 'rotate(0deg)',
  },
};

const sidebarItem = {
  w: '100%',
  h: '48px',
  border: 'none',
  borderLeft: '6px solid',
  paddingLeft: '18px',
  borderColor: 'gray.50',
  '> span': {
    overflow: 'hidden',
  },
  _hover: {
    textDecor: 'none',
    borderLeft: '6px solid',
    borderColor: 'gray.900',
  },
  _active: {
    boxShadow: 'none',
    extDecor: 'none',
    background: 'gray.250',
    borderLeft: '6px solid',
    borderColor: 'geberit.500',
  },
  _disabled: {
    color: 'gray.400',
    opacity: 1,
  },
};

const Button = {
  baseStyle: {
    borderRadius: 0,
    border: '1px solid',
    fontWeight: 500,
    textAlign: 'left',
    _focus: {
      boxShadow: 'none',
    },
  },
  variants: {
    primary,
    secondary,
    secondaryRound: {
      ...secondary,
      ...{
        borderRadius: '100%',
      },
    },
    flat,
    filled,
    filledRound: {
      ...filled,
      ...{
        borderRadius: '100%',
      },
    },
    link,
    approve,
    decline,
    dropdown,
    multiselect,
    sidebarItem,
    inlineLink,
  },
  sizes: {
    xs: {
      padding: '5px 24px',
      minWidth: '32px',
      minHeight: '32px',
      fontSize: '14px',
      '*': {
        fontSize: '14px',
      },
      svg: {
        width: '8px',
        height: '8px',
      },
    },
    sm: {
      padding: '5px 24px',
      minWidth: '36px',
      minHeight: '36px',
      fontSize: '14px',
      '*': {
        fontSize: '14px',
      },
      svg: {
        width: '8px',
        height: '8px',
      },
    },
    md: {
      svg: {
        width: '16px',
        height: '16px',
        // width: '14px',
        // height: '14px',
      },
    },
    lg: {
      svg: {
        width: '20px',
        height: '20px',
      },
    },
  },
  defaultProps: {
    variant: 'flat',
    size: 'md',
  },
};

export default Button;
