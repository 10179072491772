import {
  Box,
  Button,
  Link,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';

import PaperClip from '../../icons/PaperClip';
import { getTestID } from '../../utils/utils';
import DownloadIcon from '../../icons/DownloadIcon';

function DownloadAssetsFile({ assetsFiles = [] }) {
  if (!assetsFiles || assetsFiles.length === 0) {
    return null;
  }

  if (assetsFiles.length === 1) {
    return (
      <Box>
        <Link href={assetsFiles[0].fileLink} target="_blank">
          <Button data-testid={getTestID('DownloadAssetsFile')} padding={0} marginLeft="2" variant="filledRound">
            <PaperClip />
          </Button>
        </Link>
      </Box>
    );
  }

  return (
    <Box>
      <Menu gutter={0}>
        <MenuButton
          as={IconButton}
          aria-label="Options"
          icon={<PaperClip />}
          variant="filledRound"
          data-testid={getTestID('DownloadAssetsFile')}
        />
        <MenuList>
          {assetsFiles.map((file, index) => (
            <MenuItem key={file.filename}>
              <Button
                as="a"
                variant="link"
                href={file.fileLink}
                target="_blank"
                leftIcon={<DownloadIcon />}
              >
                {file.filename || `File ${index + 1}`}

              </Button>
              {/* <Link href={file.fileLink} target="_blank" isExternal>
                {file.filename || `File ${index + 1}`}
              </Link> */}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Box>
  );
}

DownloadAssetsFile.propTypes = {
  assetsFiles: PropTypes.arrayOf(PropTypes.shape({
    fileLink: PropTypes.string.isRequired,
    fileName: PropTypes.string,
  })),
};

export default DownloadAssetsFile;
