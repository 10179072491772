import {
  Heading,
  Box,
  Button,
  Flex,
  Spacer,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  Divider,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { ArrowDownIcon } from '@chakra-ui/icons';
import PropTypes from 'prop-types';

import { useArticleAssetsAnonymousList } from '../../api/hooks';
import GeberitSpinner from '../ui/GeberitSpinner';
import Alert from '../ui/Alert';
import DexAssetsTableRow from './DexAssetsTableRow';

function DexAssetsList({ fetchNextPageProps, hasNextPageProps, isFetchingNextPageProps }) {
  const {
    isLoading,
    isSuccess,
    isError,
    error,
    data: catalogueList,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useArticleAssetsAnonymousList();

  const pages = catalogueList?.pages;
  const totalEntries = pages?.reduce((count, curr) => count + (curr.entries?.length ?? 0), 0);
  const rowTotal = pages?.[0]?.rowTotal;

  if (isLoading) return <GeberitSpinner />;
  if (isError) {
    return (
      <Box>
        <Divider />
        <Alert variant="error">
          {error?.message || JSON.stringify(error)}
        </Alert>
      </Box>
    );
  }

  return (
    <Box>
      <Divider />
      <AssetHeader totalEntries={totalEntries} rowTotal={rowTotal} />
      {isSuccess && rowTotal > 0 && (
        <>
          <Table>
            <TableHeader />
            <Tbody>
              {pages?.map((page) => page?.entries?.map((entry) => entry?.revisions?.length > 0 && (
              <DexAssetsTableRow
                key={entry.catalogue.uuid}
                catalogue={entry.catalogue}
                revision={entry.revisions[0]}
              />
              )))}
            </Tbody>
          </Table>
          <Pagination
            fetchNextPage={fetchNextPageProps || fetchNextPage}
            hasNextPage={hasNextPageProps || hasNextPage}
            isFetchingNextPage={isFetchingNextPageProps || isFetchingNextPage}
            totalEntries={totalEntries}
            rowTotal={rowTotal}
          />
        </>
      )}
    </Box>
  );
}

DexAssetsList.propTypes = {
  fetchNextPageProps: PropTypes.func,
  hasNextPageProps: PropTypes.bool,
  isFetchingNextPageProps: PropTypes.bool,
};

function AssetHeader({ totalEntries, rowTotal }) {
  const { t } = useTranslation();

  return (
    <Box marginBottom="8">
      <Heading as="h1" size="h1">
        {t('dexAssetsPortal.yourRequestedAssets')}
        {totalEntries > 0 && (
          <Text as="span">
            {' '}
            (
            {totalEntries}
            {' '}
            {t('catalogues.pagination.of')}
            {' '}
            {rowTotal}
            )
          </Text>
        )}
      </Heading>
      <Text>{t('dexAssetsPortal.yourRequestedAssetsExplanation')}</Text>
    </Box>
  );
}

AssetHeader.propTypes = {
  totalEntries: PropTypes.number.isRequired,
  rowTotal: PropTypes.number.isRequired,
};

function TableHeader() {
  const { t } = useTranslation();

  return (
    <Thead>
      <Tr>
        <Th>{t('dexAssetsPortal.assortment')}</Th>
        <Th>{t('country')}</Th>
        <Th>{t('language')}</Th>
        <Th>{t('dexAssetsPortal.scope')}</Th>
        <Th>{t('createdOn')}</Th>
        <Th>{t('dexAssetsPortal.validUntil')}</Th>
        <Th>{t('download')}</Th>
        <Th>{t('delete')}</Th>
      </Tr>
    </Thead>
  );
}

function Pagination({
  fetchNextPage, hasNextPage, isFetchingNextPage, totalEntries, rowTotal,
}) {
  const { t } = useTranslation();

  return (
    <Flex>
      <Spacer />
      {hasNextPage && (
        <Button
          onClick={fetchNextPage}
          disabled={!hasNextPage || isFetchingNextPage}
          leftIcon={<ArrowDownIcon />}
        >
          {isFetchingNextPage ? t('loadingMore') : t('loadMore')}
        </Button>
      )}
      <Spacer />
      <Text alignSelf="center">
        (
        {totalEntries}
        {' '}
        {t('catalogues.pagination.of')}
        {' '}
        {rowTotal}
        )
      </Text>
    </Flex>
  );
}

Pagination.propTypes = {
  fetchNextPage: PropTypes.func.isRequired,
  hasNextPage: PropTypes.bool.isRequired,
  isFetchingNextPage: PropTypes.bool.isRequired,
  totalEntries: PropTypes.number.isRequired,
  rowTotal: PropTypes.number.isRequired,
};

export default DexAssetsList;
