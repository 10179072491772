import {
  Box,
  Divider,
  Grid,
  GridItem,
  Text,
  Button,
  Heading,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { useDownloadUsageLog } from '../../api/hooks';
import { getTestID } from '../../utils/utils';
import DownloadIcon from '../../icons/DownloadIcon';
import Error from '../ui/Error';
import GeberitSpinner from '../ui/GeberitSpinner';

function TabUsageLog() {
  const { t } = useTranslation();
  const {
    downloadUsageLog, progress, setProgress, error,
  } = useDownloadUsageLog();

  return (
    <Box data-testid={getTestID('TabUsageLog')}>
      <Grid gridGap="8">
        <GridItem>
          <Heading as="h1" size="md">
            {t('usageLog.title')}
          </Heading>
          <Text size="lg">
            {t('usageLog.subtitle')}
          </Text>
          <Divider />
        </GridItem>
        <GridItem>
          <Box data-testid={getTestID('DownloadUsageLog')}>
            <Button
              leftIcon={<DownloadIcon />}
              isLoading={progress}
              onClick={() => {
                setProgress(true);
                downloadUsageLog();
              }}
              variant="primary"
              spinner={<GeberitSpinner />}
              loadingText={t('usageLog.downloading')}
            >
              {t('usageLog.download')}
            </Button>
            {error && <Error error={error} />}
          </Box>
        </GridItem>
      </Grid>
    </Box>
  );
}

export default TabUsageLog;
