import {
  Box,
  Heading,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { useSyncData } from '../../api/hooks';
import useUserStore from '../../services/UserStore';
import { roles } from '../../utils/constants';
import {
  formatDate,
  getLocale,
} from '../../utils/utils';
import Error from '../ui/Error';
import KeyValuePairs from '../ui/KeyValuePairs';

function SyncStatus({ locale }) {
  const { t } = useTranslation();
  const user = useUserStore((s) => s.user);

  const { data: syncData, error } = useSyncData();

  const keyValuePairs = [
    {
      key: t('market'),
      value: getLocale(locale) || locale,
    },
    ...(user.role === roles.MDM || user.role === roles.ADMIN ? [{
      key: t('syncStatus.lastSyncToCtp'),
      value: syncData ? formatDate(syncData[0].lastSyncToCtp) : '',
    }] : []),
    {
      key: (user.role === roles.MDM || user.role === roles.ADMIN)
        ? t('syncStatus.lastSyncToFileExport')
        : t('syncStatus.mostRecent'),
      value: syncData ? formatDate(syncData[0].lastSyncToFileExport) : '',
    },
  ];

  return (
    <Box
      id="SyncStatus"
      padding="12"
      background="gray.50"
    >
      <Heading margin="0">{t('syncStatus.heading')}</Heading>
      {error && <Error error={error} />}
      <KeyValuePairs list={keyValuePairs} templateColumns={{ base: '1fr' }} maxW="100%" gridGap="4" />
    </Box>
  );
}

SyncStatus.propTypes = {
  locale: PropTypes.string.isRequired,
};

export default SyncStatus;
