import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Collapse,
  Divider,
  Flex,
  Heading,
  Text,
  Textarea,
  FormLabel,
} from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useCatalogue, useCreateRevision } from '../../api/hooks';
import LeftArrowIcon from '../../icons/LeftArrowIcon';
import {
  formatParametersPresetsData,
  getTestID,
} from '../../utils/utils';
import SecondsAsEstimatedTime from '../common/SecondsAsEstimatedTime';
import Back from '../ui/Back';
import GeberitSpinner from '../ui/GeberitSpinner';
import KeyValuePairs from '../ui/KeyValuePairs';
import SyncStatus from './SyncStatus';

const initialValues = {
  description: '',
};

function BackButton({ parentUuid }) {
  const { t } = useTranslation();
  return (
    <Back to={`/catalogue/${parentUuid}`} text={t('backToCatalogue')} />
  );
}

BackButton.propTypes = {
  parentUuid: PropTypes.string.isRequired,
};

function CatalogueInfo({ catalogue }) {
  const { t } = useTranslation();
  return (
    <Flex direction={{ base: 'column', md: 'row' }} justifyContent="space-between" gap={8}>
      <Box flex={{ base: '0 0 100%', md: '0 0 50%' }}>
        <Heading>{catalogue.name}</Heading>
        <KeyValuePairs
          list={[{ key: t('transformationType'), value: t(`transformations.${catalogue.exchangeStandard}.pretty_name`) }]}
          templateColumns={{ base: '1fr' }}
          maxW="60%"
          gridGap="4"
        />
        <SecondsAsEstimatedTime totalSeconds={catalogue?.averageTimeTakenSec} />
      </Box>
      <Box flex={{ base: '0 0 100%', md: '0 0 45%' }} alignSelf={{ base: 'auto', md: 'auto' }}>
        <SyncStatus locale={catalogue.locale} />
      </Box>
    </Flex>
  );
}

CatalogueInfo.propTypes = {
  catalogue: PropTypes.shape({
    name: PropTypes.string.isRequired,
    exchangeStandard: PropTypes.string.isRequired,
    averageTimeTakenSec: PropTypes.number,
    locale: PropTypes.string.isRequired,
  }).isRequired,
};

function ParametersSection({ parametersVisible, setParametersVisible, parameterKeyValues }) {
  const { t } = useTranslation();
  return (
    <>
      <Flex justifyContent="space-between">
        <Heading margin={0} alignSelf="center">
          {t('inputParameters')}
          &nbsp;
        </Heading>
        <Button
          variant="link"
          alignSelf="center"
          onClick={() => setParametersVisible(!parametersVisible)}
        >
          {parametersVisible ? t('hide') : t('show')}
        </Button>
      </Flex>
      <Collapse in={parametersVisible}>
        <KeyValuePairs list={parameterKeyValues} />
      </Collapse>
      <Divider />
    </>
  );
}

ParametersSection.propTypes = {
  parametersVisible: PropTypes.bool.isRequired,
  setParametersVisible: PropTypes.func.isRequired,
  parameterKeyValues: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  })).isRequired,
};

function PresetsSection({ presetsVisible, setPresetsVisible, presetKeyValues }) {
  const { t } = useTranslation();
  return (
    <>
      <Flex justifyContent="space-between">
        <Heading margin={0} alignSelf="center">
          {t('transformationPresets')}
        </Heading>
        <Button
          variant="link"
          alignSelf="center"
          onClick={() => setPresetsVisible(!presetsVisible)}
        >
          {presetsVisible ? t('hide') : t('show')}
        </Button>
      </Flex>
      <Collapse in={presetsVisible}>
        <KeyValuePairs list={presetKeyValues} />
      </Collapse>
      <Divider />
    </>
  );
}

PresetsSection.propTypes = {
  presetsVisible: PropTypes.bool.isRequired,
  setPresetsVisible: PropTypes.func.isRequired,
  presetKeyValues: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  })).isRequired,
};

function DescriptionSection({ getFieldProps }) {
  const { t } = useTranslation();
  return (
    <>
      <FormLabel>
        <Heading>
          {`2. ${t('description')}`}
        </Heading>
      </FormLabel>
      <Text>{t('revision.descriptionBlurb')}</Text>
      <Textarea
        data-testid={getTestID('RevisionDescriptionText')}
        name={getFieldProps('description')?.name}
        onChange={getFieldProps('description').onChange}
        onBlur={getFieldProps('description').onBlur}
        value={getFieldProps('description').value}
        maxLength={255}
      />
      <Divider />
    </>
  );
}

DescriptionSection.propTypes = {
  getFieldProps: PropTypes.func.isRequired,
};

function FormFooter({ navigate, parentUuid, isSubmitting }) {
  const { t } = useTranslation();
  return (
    <Flex justifyContent="space-between">
      <Button
        variant="link"
        onClick={() => navigate(`/catalogue/${parentUuid}`)}
        paddingLeft={0}
      >
        <LeftArrowIcon />
        {t('backToCatalogue')}
      </Button>
      <Button
        data-testid={getTestID('newCatalogueRevisionButton')}
        variant="primary"
        type="submit"
        isDisabled={isSubmitting}
        leftIcon={isSubmitting && <GeberitSpinner />}
      >
        {t('revision.create')}
      </Button>
    </Flex>
  );
}

FormFooter.propTypes = {
  navigate: PropTypes.func.isRequired,
  parentUuid: PropTypes.string.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

function RevisionCreate() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const parentUuid = searchParams.get('parentUuid');

  const [presetsVisible, setPresetsVisible] = useState(false);
  const [parametersVisible, setParametersVisible] = useState(true);

  const { data: catalogue, isLoading: catalogueLoading } = useCatalogue(parentUuid);

  const { mutate: createRevision } = useCreateRevision();

  const handleCreateRevision = (revision) => {
    createRevision(
      revision,
      {
        onSuccess: () => {
          navigate(`/catalogue/${parentUuid}`);
        },
      },
    );
  };

  const parameterKeyValues = catalogue?.parameters?.map((param) => ({
    key: t(`parameters.${param.name}.label`),
    value: formatParametersPresetsData(param, t('notSpecified')),
  }));

  const presetKeyValues = catalogue?.presets?.map((preset) => ({
    key: t(`presets.${catalogue.exchangeStandard}.${preset.name}.label`),
    value: formatParametersPresetsData(preset, t('notSpecified')),
  }));

  return (
    <Box data-testid={getTestID('RevisionCreate')}>
      <BackButton parentUuid={parentUuid} />
      <Heading as="h1" size="h1">
        {t('revision.create')}
      </Heading>
      {catalogueLoading && <GeberitSpinner />}
      {catalogue && (
        <Formik
          initialValues={initialValues}
          autoComplete="off"
          onSubmit={(values) => {
            handleCreateRevision({
              revision: {
                ...values,
                revisionParent: parentUuid,
                updatedOn: null,
                version: '0.0',
              },
            });
          }}
        >
          {({ getFieldProps, isSubmitting }) => (
            <Form>
              <CatalogueInfo catalogue={catalogue} />
              <Divider />
              {catalogue.parameters?.length > 0 && (
                <ParametersSection
                  parametersVisible={parametersVisible}
                  setParametersVisible={setParametersVisible}
                  parameterKeyValues={parameterKeyValues}
                />
              )}
              {catalogue.presets?.length > 0 && (
                <PresetsSection
                  presetsVisible={presetsVisible}
                  setPresetsVisible={setPresetsVisible}
                  presetKeyValues={presetKeyValues}
                />
              )}
              <DescriptionSection getFieldProps={getFieldProps} />
              <FormFooter navigate={navigate} parentUuid={parentUuid} isSubmitting={isSubmitting} />
            </Form>
          )}
        </Formik>
      )}
    </Box>
  );
}

export default RevisionCreate;
