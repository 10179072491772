const breakpoints = {
  base: '0px',
  xs: '319px',
  sm: '767px',
  md: '1023px',
  lg: '1279px',
  xl: '1440px',
  '2xl': '1800px',
};

export default breakpoints;

// const breakpoints = {
//   xs: '0px',
//   sm: '768px',
//   md: '1024px',
//   lg: '1272px',
//   xl: '1440px',
//   '2xl': '1800px',
// };

// export default breakpoints;
