import CommonTable from './CommonTable';
import { usePaginatedUsersList } from '../../../../api/hooks';
import { roles } from '../../../../utils/constants';

export function ActiveUsersTable() {
  const filters = { roles: `${roles.ADMIN},${roles.LDES},${roles.MDM}` };
  const {
    loading,
    data: users,
    error,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = usePaginatedUsersList('active', filters);

  return (
    <CommonTable
      type="active"
      users={users}
      hasRolesAssigned
      loading={loading}
      error={error}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      isFetchingNextPage={isFetchingNextPage}
    />
  );
}

export function PendingUsersTable() {
  const filters = { roles: '' };

  const {
    loading,
    data: users,
    error,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = usePaginatedUsersList('pending', filters);

  return (
    <CommonTable
      type="pending"
      users={users}
      loading={loading}
      error={error}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      isFetchingNextPage={isFetchingNextPage}
    />
  );
}

export function DefaultUsersTable() {
  const filters = { roles: `${roles.DEFAULT}` };
  const {
    loading,
    data: users,
    error,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = usePaginatedUsersList('active', filters);

  return (
    <CommonTable
      type="active"
      users={users}
      loading={loading}
      error={error}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      isFetchingNextPage={isFetchingNextPage}
    />
  );
}
