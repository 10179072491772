import {
  Box,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { getTestID } from '../../utils/utils';
import { useTransformationsRunning } from '../../api/hooks';
import Alert from '../ui/Alert';

function RunningTransformationsTotal() {
  const { t } = useTranslation();

  const {
    data: transformationsRunning, isError, error,
  } = useTransformationsRunning();

  if (isError) {
    return (
      <Alert variant="error">
        {error?.message || JSON.stringify(error)}
      </Alert>
    );
  }

  return (
    <Box
      marginBottom="4"
    >
      {transformationsRunning && (
        <Text data-testid={getTestID('TransformationsRunning')}>
          {t('transformations.running')}
          :&nbsp;
          {transformationsRunning.total}
        </Text>
      )}
    </Box>
  );
}

export default RunningTransformationsTotal;
