import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Input,
  Box,
  Flex,
  Heading,
  Text,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';

import { useUploadFile } from '../../../api/hooks';
import { getTestID } from '../../../utils/utils';
import Alert from '../../ui/Alert';
import Error from '../../ui/Error';

function FileUpload({
  uuid,
  setNewRevisionLink,
  accept = '',
}) {
  const { t } = useTranslation();
  const [file, setFile] = useState();
  const [formData, setFormData] = useState();
  const [progress, setProgress] = useState();
  const hiddenFileInput = useRef(null);

  const {
    mutate: uploadFile, isError, error,
  } = useUploadFile(uuid, setProgress);

  const handleFileInputClick = () => {
    hiddenFileInput.current.click();
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const fd = new FormData();
      fd.append('file', selectedFile);
      setFile(selectedFile);
      setFormData(fd);
    }
  };

  const handleFileUpload = () => {
    if (formData) {
      uploadFile(formData, {
        onSuccess: (res) => {
          setNewRevisionLink(res.data);
          setFile(null);
          setFormData(null);
          setProgress(null);
        },
        onError: () => {
          setFile(null);
          setFormData(null);
          setProgress(null);
        },
      });
    }
  };

  return (
    <Box
      data-testid={getTestID('FileUpload')}
      background="gray.150"
      padding="12"
    >
      <Heading>{t('fileUpload.heading')}</Heading>
      <Text>{t('fileUpload.blurb')}</Text>
      {!progress && (
        <Flex justifyContent="space-between" marginTop="4">
          <Input
            ref={hiddenFileInput}
            style={{ display: 'none' }}
            type="file"
            onChange={handleFileChange}
            accept={`.${accept}`}
          />
          <Flex align="center">
            <Button
              variant="secondary"
              onClick={handleFileInputClick}
              marginRight="2"
            >
              {t('fileUpload.browse')}
            </Button>
            {file?.name ? (
              <Text color="gray.700" align="center">
                {file?.name}
              </Text>
            ) : (
              <Text color="gray.700" align="center">
                {t('fileUpload.pleaseSelectFile')}
              </Text>
            )}
          </Flex>
          <Button
            variant="primary"
            onClick={handleFileUpload}
            disabled={!file || !file?.name.includes(accept)}
          >
            {t('revision.create')}
          </Button>
        </Flex>
      )}
      {progress && (
        <Box background="gray.250">
          <Box width={`${progress}%`} height="6px" background="gray.900" />
        </Box>
      )}
      <Text size="sm" marginTop="2">
        {t('fileUpload.supported')}
        :
        {accept || 'any'}
      </Text>
      {isError && <Error error={error} marginTop="4" />}
      {file && !file?.name.includes(accept) && (
        <Alert variant="error">
          {t('fileUpload.wrongType')}
          :
          {accept}
        </Alert>
      )}
    </Box>
  );
}

FileUpload.propTypes = {
  uuid: PropTypes.string.isRequired,
  accept: PropTypes.string,
  setNewRevisionLink: PropTypes.func.isRequired,
};

export default FileUpload;
