import {
  Box,
  Grid,
  Text,
  Tooltip,
  Flex,
  Textarea,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';

import { getTestID } from '../../utils/utils';

// Helper function to check if the value is a comma or \n separated list with >3 items.
const isLongList = (value) => {
  if (typeof value !== 'string') return false;

  const commaSeparated = value.split(',').map((item) => item.trim());
  const newlineSeparated = value.split('\n').map((item) => item.trim());

  return (
    (commaSeparated.length > 3 || newlineSeparated.length > 3)
  );
};

// Helper function to check if the string contains any whitespace
const hasWhitespace = (value) => /\s/.test(value);

function KeyValuePairs({
  list,
  templateColumns = { base: '1fr', md: 'repeat(2, 1fr)', lg: 'repeat(3, 1fr)' },
  maxW = 'lg',
  gridGap = '8',
  containerPadding = '8',
}) {
  return (
    <Box paddingBottom={containerPadding} paddingTop={containerPadding}>
      <Grid
        templateColumns={templateColumns}
        gap={gridGap}
      >
        {list.map((pair) => (
          <Box
            key={pair.key}
            borderRadius="md"
            maxW={maxW}
            overflow="hidden"
          >
            <Flex>
              <Tooltip label={pair.key}>
                <Text isTruncated w="50%" color="gray.700">{pair.key}</Text>
              </Tooltip>
              <Box flex="1" ml={2} overflow="hidden">
                {isLongList(pair.value) ? (
                  <Textarea
                    data-testid={getTestID('CustomArticleListScrollBox')}
                    isReadOnly
                    value={pair.value}
                    overflowY="auto"
                    whiteSpace="pre-wrap" // Preserve line breaks
                  />
                ) : (
                  <Tooltip label={pair.value} hasArrow>
                    <Text
                      overflow="hidden"
                      textOverflow="ellipsis"
                      whiteSpace={hasWhitespace(pair.value) ? 'normal' : 'nowrap'}
                      maxW="100%"
                    >
                      {pair.value}
                    </Text>
                  </Tooltip>
                )}
              </Box>
            </Flex>
          </Box>
        ))}
      </Grid>
    </Box>
  );
}

KeyValuePairs.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    width: PropTypes.string,
  })).isRequired,
  templateColumns: PropTypes.shape({
    base: PropTypes.string,
  }),
  maxW: PropTypes.string,
  gridGap: PropTypes.string,
  containerPadding: PropTypes.string,
};

export default KeyValuePairs;
